




































































































import Vue from "vue";
import Login from "@/components/Login.vue";
import Password from "@/components/Password.vue";

export default Vue.extend({
  name: "ChangePassword",
  components: { Login, Password },
  data() {
    return {
      valid: false,
      e1: 1,
      updateLoading: false,
      password: "",
      passwordConfirmation: "",
      passwordConfirmationRules: []
    };
  },
  mounted() {
    this.passwordConfirmationRules = [
      v => !!v || this.$i18n.t("components/register/errors/password_empty"),
      v =>
        v === this.password ||
        this.$i18n.t("views/register/errors/password_mismatch")
    ];
  },
  methods: {
    updatePassword(): void {
      this.updateLoading = true;
      this.$store
        .dispatch("user/updatePassword", this.password)
        .then(() => {
          this.$store.commit("showMessage", {
            text: this.$t("views/password_change/message/success"),
            color: "success"
          });
          this.$router.push({ name: "profile" });
        })
        .catch((error: string) => {
          this.showError(error);
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },

    cancel(): void {
      this.$router.push({ name: "profile" });
    },

    showError(error: string): void {
      let textError;
      switch (error) {
        case "auth/weak-password":
          textError = this.$t("components/register/errors/password_weak");
          break;
        default:
          textError = this.$t("views/password_change/message/error");
          break;
      }
      this.$store.commit("showMessage", { text: textError, color: "error" });
    }
  }
});
