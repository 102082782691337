



























































































































import Vue from "vue";
import Login from "@/components/Login.vue";

export default Vue.extend({
  name: "DeleteAccount",
  components: { Login },
  data: () => ({
    e1: 1,
    deleteLoading: false,
    checkbox: false
  }),
  methods: {
    deleteAccount(): void {
      this.deleteLoading = true;
      this.$store
        .dispatch("user/delete")
        .then(() => {
          this.$store.commit("showMessage", {
            text: this.$t("views/profile_delete/message/success"),
            color: "success"
          });
          this.$router.push({ name: "home" });
        })
        .catch(error => {
          this.$store.commit("showMessage", {
            text: this.$t("views/profile_delete/message/error"),
            color: "error"
          });
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },

    cancel(): void {
      this.$router.push({ name: "profile" });
    }
  }
});
